body, html, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden; /* Prevents horizontal scroll due to overflow */
}

* {
  box-sizing: border-box;
}
